import React from 'react';
import PropTypes from 'prop-types';

const SelectedStandard = (props) => (
  <li>
    {props.hex ? <span className="__colortip" style={{ backgroundColor: props.hex }} /> : ''}
    {props.name}
  </li>
);

SelectedStandard.defaultProps = {
  hex: null
};
SelectedStandard.propTypes = {
  name: PropTypes.string.isRequired,
  hex: PropTypes.string
};

export default SelectedStandard;
