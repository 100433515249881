import React from 'react';
import PropTypes from 'prop-types';

export default class FlashNotifier extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      closed: false
    };

    this.onClickCloseToast = this.onClickCloseToast.bind(this);

    setInterval(() => {
      this.setState({ closed: true });
    }, 5000);
  }

  onClickCloseToast() {
    this.setState({ closed: true });
  }

  render() {
    if (this.state.closed) return '';

    return (
      <div className={`alert_module ${this.props.type === 'alert' ? 'is-error' : 'is-success'}`}>
        <p className="alert_message">{this.props.message}</p>
        <span className="close_button" onClick={this.onClickCloseToast} />
      </div>
    );
  }
}

FlashNotifier.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
};
