import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = []

  initialize() {
  }

  perform() {
    this.element.remove()
  }
}
