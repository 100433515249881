import React from 'react';
import PropTypes from 'prop-types';

import Spinner from '../shared/Spinner';

export default class RegistrationButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSpinnerShow: false
    };

    this.viewSpinner = this.viewSpinner.bind(this);
  }

  viewSpinner() {
    this.setState({ isSpinnerShow: true });
  }

  render() {
    return (
      <>
        <a href={this.props.path} className="btn__default __normal __blue" onClick={this.viewSpinner}>同意する</a>
        <Spinner isShow={this.state.isSpinnerShow} />
      </>
    );
  }
}

RegistrationButton.propTypes = {
  path: PropTypes.string.isRequired
};
