import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'submit' ]

  static values = {
  }

  connect() {
  }

  redirectToProducts() {
    window.location.href = event.detail[0].path
  }

  checkToSubmittable() {
    if (this.element.querySelectorAll('[data-uploaded="false"]').length > 0) {
      event.preventDefault()
      event.stopPropagation()
    }
  }
}
