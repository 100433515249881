import React from 'react';
import PropTypes from 'prop-types';

const MONTHS_OF_YEAR = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export default class DataPickerCalender extends React.Component {
  static proceedsUrl(targetMonth) {
    return `/creators/proceeds?target_month=${targetMonth}`;
  }

  constructor(props) {
    super(props);

    this.currentDate = new Date();
  }

  isDisabled(month) {
    if (this.props.year < this.currentDate.getFullYear()) return false;
    if (this.props.year > this.currentDate.getFullYear()) return true;

    if (month > (this.currentDate.getMonth() + 1)) return true;

    return false;
  }

  isActive(month) {
    return this.props.year === this.props.selectedYear && month === this.props.selectedMonth;
  }

  itemClassName(month) {
    const defaultClassName = ['month__item'];
    if (this.isDisabled(month)) defaultClassName.push('is-disabled');
    if (this.isActive(month)) defaultClassName.push('is-active');

    return defaultClassName.join(' ');
  }

  viewCalenderItem(month) {
    const monthStr = `${month}月`;
    const itemClassName = this.itemClassName(month);

    if (this.isDisabled(month)) {
      return <li className={itemClassName} key={month}>{monthStr}</li>;
    }
    const targetMonth = `${this.props.year}/${month}`;

    return (
      <li className={itemClassName} key={month}>
        <a href={DataPickerCalender.proceedsUrl(targetMonth)} tabIndex="-1">{monthStr}</a>
      </li>
    );
  }

  render() {
    return (
      <div className="sale__month" data-slick-index="0" aria-hidden="true" tabIndex="-1">
        <h3 className="sale__month__year">{`${this.props.year}年`}</h3>
        <ul>
          {MONTHS_OF_YEAR.map((month) => (this.viewCalenderItem(month)))}
        </ul>
      </div>
    );
  }
}

DataPickerCalender.propTypes = {
  year: PropTypes.number.isRequired,
  selectedMonth: PropTypes.number.isRequired,
  selectedYear: PropTypes.number.isRequired
};
