import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import * as lib from 'src/javascripts/concerns'

export default class extends Controller {
  static targets = [
    'canvas', 'submitButton'
  ]

  connect() {
  }

  renderToCanvas() {
    this.canvasTarget.innerHTML = event.detail[2].response
  }

  failImporting() {
    this.canvasTarget.innerHTML = event.detail[2].response
  }

  redirect() {
    this.submitButtonTarget.classList.add('d-none')

    const json = event.detail[0]
    window.location.href = json.path
  }

  failRegistrationAlert() {
    const message = event.detail[0].errors.join("\n")
    alert(message)
  }
}
