import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['selector']

  static values = {
  }

  initialize() {
  }

  draw() {
    this.selectorTargets.forEach((target) => {
      target.classList.remove('selected')
    })
    event.target.querySelector('label').classList.add('selected');

    const target = document.querySelector('#first-products')

    target.innerHTML = event.detail[2].response;
  }
}
