import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ 'text', 'list' ]

  connect() {
  }

  search() {
    this.listTarget.selectedIndex = -1

    if (this.textTarget.value.length < 1) {
      return
    }

    if (this.textTarget.value.length < 2) {
      this.listTarget.querySelectorAll('option').forEach(option => {
        option.removeAttribute('disabled')
        option.classList.remove('d-none')
      })
      return
    }

    const text = this.textTarget.value
    this.listTarget.querySelectorAll('option').forEach(option => {
      let regex = new RegExp(text)
      if (option.innerText.match(regex)) {
        option.removeAttribute('disabled')
        option.classList.remove('d-none')
      } else {
        option.setAttribute('disabled', true)
        option.classList.add('d-none')
      }
    })
 
    this.activeListSize()
  }

  select() {
    this.closeList()
  }

  activeListSize() {
    const size = this.listTarget.querySelectorAll('option:not([disabled=true])').length
    this.listTarget.setAttribute('size', size)
  }

  closeList() {
    this.listTarget.setAttribute('size', 1)
  }
}
