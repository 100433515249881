import React from 'react';
import PropTypes from 'prop-types';

import RelatedLinkViewer from './RelatedLinkViewer';
import ImageFileField from '../shared/Forms/ImageFileField';

const RELATED_LINKS = [
  { value: 'twitter', label: 'Twitter' },
  { value: 'instagram', label: 'Instagram' },
  { value: 'facebook', label: 'Facebook' },
  { value: 'line', label: 'LINE' },
  { value: 'youtube', label: 'Youtube' },
  { value: 'tiktok', label: 'TikTok' },
  { value: 'related_link', label: '関連リンク' }];

export default class RelatedLinksForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentRelatedLinks: this.props.currentRelatedLinks,
      serviceName: '',
      label: '',
      link: ''
    };

    this.serviceNameSelectRef = React.createRef();
    this.labelTextRef = React.createRef();
    this.linkTextRef = React.createRef();
    this.iconFileRef = React.createRef();

    this.onChangeLabel = this.onChangeLabel.bind(this);
    this.onChangeLink = this.onChangeLink.bind(this);
    this.onChangeServiceName = this.onChangeServiceName.bind(this);
    this.onClickAddRelatedLink = this.onClickAddRelatedLink.bind(this);
  }

  onChangeLabel(e) {
    this.setState({ label: e.target.value });
  }

  onChangeLink(e) {
    this.setState({ link: e.target.value });
  }

  onChangeServiceName(e) {
    const selectedServiceName = e.target.value;

    this.setState({ serviceName: selectedServiceName });
  }

  onClickAddRelatedLink() {
    if (!this.isAddableRelatedLink()) return false;

    const newRelatedLink = {
      service: this.state.serviceName,
      title: this.state.label,
      link: this.state.link
    };

    const iconValue = this.iconFileRef.current.imageFileFieldRef.current.files[0];
    const iconImage = this.iconFileRef.current.state.image;
    if (iconValue) {
      newRelatedLink.imageValue = iconValue;
      newRelatedLink.image = iconImage;
    }

    this.setState((prevState) => {
      const elderRelatedLinks = prevState.currentRelatedLinks;
      elderRelatedLinks.push(newRelatedLink);

      return {
        currentRelatedLinks: elderRelatedLinks, serviceName: '', label: '', link: ''
      };
    });

    this.serviceNameSelectRef.current.value = '';
    this.labelTextRef.current.value = '';
    this.linkTextRef.current.value = '';
    this.iconFileRef.current.reset();

    return true;
  }

  isAddableRelatedLink() {
    return this.state.serviceName.length && this.state.label.length && this.state.link.length;
  }

  render() {
    return (
      <>
        <div className="link_edit_module">
          <div className="profile_item">
            <select onChange={this.onChangeServiceName} ref={this.serviceNameSelectRef}>
              <option value="">選択してください</option>
              {RELATED_LINKS.map((link) => (
                <option value={link.value}>{link.label}</option>
              ))}
            </select>
          </div>
          <div className="profile_item">
            <h4 className="has_caution">アカウント名</h4>
            <p className="profile_note">※アカウント名など表示させたい名前を入力してください。</p>
            <input type="text" onChange={this.onChangeLabel} ref={this.labelTextRef} />
          </div>
          <div className="profile_item">
            <h4 className="has_caution">URL</h4>
            <p className="profile_note">※SNSアカウントのURLを入力してください。</p>
            <input type="text" onChange={this.onChangeLink} ref={this.linkTextRef} />
          </div>

          <>
            <h4 className="has_caution">アイコン画像</h4>
            <p className="profile_note">※関連リンクに表示したいアイコン画像を登録してください。。</p>
            <ImageFileField fieldName="creator[related_links][][image]" ref={this.iconFileRef} circlePreview />
          </>

          <div className="button_module">
            <button type="button" className="add_button" onClick={this.onClickAddRelatedLink} disabled={!this.isAddableRelatedLink()}>追加する</button>
          </div>
        </div>
        <ul className="sns__link__list">
          {this.state.currentRelatedLinks.map((relatedLink) => (
            <RelatedLinkViewer
              service={relatedLink.service}
              title={relatedLink.title}
              link={relatedLink.link}
              image={relatedLink.image}
              imageValue={relatedLink.imageValue}
              key={relatedLink.title}
            />
          ))}
        </ul>
      </>
    );
  }
}

RelatedLinksForm.propTypes = {
  currentRelatedLinks: PropTypes.arrayOf(PropTypes.object).isRequired
};
