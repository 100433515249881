import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import * as lib from 'src/javascripts/concerns'

export default class extends Controller {
  static targets = ['links']

  connect() {
  }

  switch() {
    this.linksTargets.forEach(link => {
      link.classList.toggle('d-none')
    })
  }
}
