import React from 'react';
import PropTypes from 'prop-types';

export default class SelectedStandardHiddenFields extends React.Component {
  fieldName() {
    return `goods_editor[${this.props.fieldName}][]`;
  }

  render() {
    return (
      this.props.ids.map((id) => <input type="hidden" value={id} name={this.fieldName()} key={id} />)
    );
  }
}

SelectedStandardHiddenFields.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.any).isRequired,
  fieldName: PropTypes.string.isRequired
};
