import React from 'react';
import PropTypes from 'prop-types';

export default class RadioButtonField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: this.props.checked
    };

    this.onCheckedHandle = this.onCheckedHandle.bind(this);
  }

  onCheckedHandle() {
    if (this.state.checked) return false;

    this.props.onCheckedHandle(this.props.value);

    return true;
  }

  selectedClassName() {
    return this.state.checked ? this.props.selectedClassName : '';
  }

  render() {
    return (
      <>
        <input
          className={this.props.className}
          type="radio"
          value={this.props.value}
          checked={this.state.checked}
          name={this.props.fieldName}
          id={this.props.htmlFor}
          onChange={this.onCheckedHandle}
        />
        <label
          className={this.selectedClassName()}
          htmlFor={this.props.htmlFor}
        >
          {this.props.label}
        </label>
      </>
    );
  }
}

RadioButtonField.defaultProps = {
  className: '__items',
  selectedClassName: 'selected',
  onCheckedHandle: () => { /* なにもしない */ }
};

RadioButtonField.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  htmlFor: PropTypes.string.isRequired,
  selectedClassName: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onCheckedHandle: PropTypes.func,
  label: PropTypes.string.isRequired
};
