import React from 'react';
import PropTypes from 'prop-types';

export default class ImageFileField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      image: this.props.image,
      label: ''
    };
    this.imageFileFieldRef = React.createRef();

    this.onUploadImage = this.onUploadImage.bind(this);
    this.onHandleDeleteButton = this.onHandleDeleteButton.bind(this);
  }

  onUploadImage(e) {
    const reader = new FileReader();

    const label = e.target.files[0].name;

    reader.onload = (fileReaderEvent) => {
      const image = fileReaderEvent.target.result;
      this.setState({ image, label });
    };
    reader.readAsDataURL(e.target.files[0]);
  }

  onHandleDeleteButton() {
    this.imageFileFieldRef.current.value = null;
    this.setState({ image: '', label: '' });
  }

  displayPreviewImage() {
    if (!this.state.image || !this.state.image.length) return '';

    return this.props.circlePreview ? (
      <figure className="profile_img" style={{ backgroundImage: `url(${this.state.image})` }} />
    ) : (
      <figure className="cover_img">
        <img src={this.state.image} alt="preview" />
      </figure>
    );
  }

  reset() {
    this.onHandleDeleteButton();
  }

  render() {
    return (
      <div className="file_upload_module">
        {this.displayPreviewImage()}
        <div className="input-group">
          <div className="form-control file-caption">
            <div className="file-caption-name">{this.state.label}</div>
          </div>
          <div className="input-group-btn">
            <div className="btn btn-primary btn-file">
              <i className="glyphicon glyphicon-folder-open" />
              <span>ファイルを選択…</span>
              <input className="form-control" id="pag__thumbnail" name={this.props.fieldName} type="file" onChange={this.onUploadImage} ref={this.imageFileFieldRef} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ImageFileField.defaultProps = {
  image: '',
  circlePreview: false
};

ImageFileField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  image: PropTypes.string,
  circlePreview: PropTypes.bool
};
