import React from 'react';
import PropTypes from 'prop-types';

export default class PrintingImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      image: null
    };

    this.onChangeImage = this.onChangeImage.bind(this);
  }

  onChangeImage(event) {
    const reader = new FileReader();
    const contentType = event.target.files[0].type;

    reader.onload = (fileReaderEvent) => {
      const image = new Image();
      image.src = fileReaderEvent.target.result;

      image.onload = () => {
        if (contentType !== 'image/png') {
          return window.alert('PNG形式の画像を指定してください');
        }

        if (!this.validImageSize(image.width, image.height)) return this.invalidImageSizeAlert();

        this.props.addSelectedImages(this.props.positionCode);
        this.setState({ image: fileReaderEvent.target.result });

        return true;
      };
    };

    reader.readAsDataURL(event.target.files[0]);
  }

  fileFieldId() {
    return `design-setting-images-${this.props.positionCode}`;
  }

  validImageSize(width, height) {
    return this.props.printWidthPx === width && this.props.printHeightPx === height;
  }

  invalidImageSizeAlert() {
    window.alert(`${this.props.printHeightPx} x ${this.props.printWidthPx} px の画像を指定してください。`);
  }

  render() {
    return (
      <div key={this.props.positionCode}>
        <h2 className="image__section__name">
          <span className="__name">{this.props.positionName}</span>
          <span className="__size">{`指定画像サイズ 縦 ${this.props.printHeightPx} px：横 ${this.props.printWidthPx} px （png形式）`}</span>
        </h2>
        <div className="image__setter">
          <figure className="__viewer">
            <img src={this.props.printingImageUrl} alt="drawable-design-board" />
          </figure>
          <div className="__setter">
            <span
              className="set__image"
              id={`js-setting-design-image-${this.props.positionCode}`}
              style={this.state.image ? { backgroundImage: `url(${this.state.image})` } : null}
            />
            <label className="btn__edit __photo __design" htmlFor={this.fileFieldId()}>
              画像・写真を選択
              <input
                type="hidden"
                name="design_settings[upload_images][][printing_code]"
                id="design_settings_upload_images__printing_code"
                value={this.props.positionCode}
              />
              <input
                type="file"
                name="design_settings[upload_images][][image]"
                id={this.fileFieldId()}
                onChange={this.onChangeImage}
              />
            </label>
          </div>
        </div>
      </div>
    );
  }
}

PrintingImage.propTypes = {
  positionName: PropTypes.string.isRequired,
  positionCode: PropTypes.string.isRequired,
  printWidthPx: PropTypes.number.isRequired,
  printHeightPx: PropTypes.number.isRequired,
  printingImageUrl: PropTypes.string.isRequired,
  addSelectedImages: PropTypes.func.isRequired
};
