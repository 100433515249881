import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ 'form', 'canvas' ]

  static values = {
    path: String
  }

  connect() {
    this.reloadThumbnails()
  }

  uploadAndConvertImage() {
    Rails.fire(this.formTarget, 'submit')
  }

  drawLoading() {
    this.canvasTarget.innerHTML = '<i class="fas fa-sync fa-spin"></i>'
  }

  failToUpload() {
    this.canvasTarget.innerHTML = ''
    this.formTarget.dataset.uploaded = false
    this.formTarget.querySelectorAll('input[type="file"]').forEach(file => {
      file.value = '';

      let trigger = new Event('change')
      file.dispatchEvent(trigger)
    })
  }

  uploaded() {
    this.reloadThumbnails()
  }

  reloadThumbnails() {
    fetch(this.pathValue, {
    }).then(response => response.text()
    ).then(html => {
      this.canvasTarget.innerHTML = html
    })
  }
}
