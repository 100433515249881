import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = []

  static values = {
    path: String,
  }

  connect() {
    this.render()
  }

  reload() {
    if (event.detail[2] && event.detail[2].responseURL) {
      this.pathValue = event.detail[2].responseURL
      this.render()
    }
  }

  render() {
    fetch(this.pathValue, {
    }).then(
      response => response.text()
    ).then(body => {
      this.element.innerHTML = body
    })
  }

  link() {
    this.pathValue = event.target.getAttribute('href')
    this.render()

    event.preventDefault()
    event.stopPropagation()
  }

  submit() {
    Rails.fire(event.target.closest('form'), 'submit')
  }
}
