import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ ]

  static values = {
    path: String
  }

  connect() {
    this.load()
  }

  load() {
    fetch(this.pathValue)
      .then((response) => {
        switch (response.status) {
          case 200:
            this.render(response.text());
            break;
          case 204:
            this.render('');
            break;
          bread:
            break;
        }
      })
  }

  async render(html) {
    this.element.innerHTML = ''
    this.element.insertAdjacentHTML('afterbegin', await html)
  }
}
