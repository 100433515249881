import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = []

  static values = {
    path: String
  }

  initialize() {
    this.load();
  }

  load() {
    fetch(this.pathValue)
      .then((response) => response.text())
      .then((html) => this.render(html));
  }

  render(html) {
    this.element.outerHTML = html // eslint-disable-line
  }

  pre_destroy() {
    let product = event.target.closest('.top-creator-product-wrap')

    product.querySelectorAll('a').forEach(atag => {
      atag.removeAttribute('href')
      atag.removeAttribute('data-confirm')
    })

    product.animate(
      [ { opacity: 0 } ],
      { duration: 180, fill: 'forwards' }
    )
  }

  destroy() {
    event.target.closest('.top-creator-product-wrap').remove()
  }
}
