import React from 'react';
import PropTypes from 'prop-types';

import 'whatwg-fetch';
import Rails from '@rails/ujs';

export default class CreatorProduct extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deletable: null,
      deleted: false
    };
  }

  async componentDidMount() {
    await this.fetchDeletable();
  }

  editPath() {
    return `/creators/products/${this.props.id}/edit`;
  }

  deletePath() {
    return `/creators/products/${this.props.id}`;
  }

  async fetchDeletable() {
    const path = `/creators/products/${this.props.id}/deletable`;
    const response = await fetch(path, {
      mode: 'cors',
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    if (response.status !== 200) return null;

    const json = await response.json();

    this.setState({ deletable: json.deletable });

    return json.deletable;
  }

  editClickHandle() {
    if (this.state.deletable) return this.redirectEditPath();

    const confirmMassage = 'この商品は既に公開（販売）されています。修正しますか？';
    if (window.confirm(confirmMassage)) {
      this.redirectEditPath();
    }

    return false;
  }

  redirectEditPath() {
    window.location.href = this.editPath();
  }

  async deleteClickHandle() {
    const confirmMessage = 'この処理は元には戻せません。本当に削除しますか？';
    if (window.confirm(confirmMessage)) {
      const response = await fetch(this.deletePath(), {
        mode: 'cors',
        method: 'DELETE',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          [Rails.csrfParam()]: Rails.csrfToken()
        })
      });

      if (response.status === 200) {
        this.setState({ deleted: true });
      } else {
        this.setState({ deleted: false });
        window.alert('削除に失敗しました');
      }
    }

    return true;
  }

  render() {
    if (this.state.deleted) return '';

    return (
      <article className="item__list__element" key={this.props.id}>
        <span>
          <figure style={{ backgroundImage: `url('${this.props.thumbnailUrl}')` }}>
            <img src={this.props.thumbnailUrl} alt="creator-product-thumbnail" />
          </figure>
          <h3>{this.props.name}</h3>
          <button type="button" className="btn__edit" onClick={() => this.editClickHandle()}>編集</button>
          {this.state.deletable ? <button type="button" className="btn__delete" onClick={() => this.deleteClickHandle()}>削除</button> : ''}
        </span>
      </article>
    );
  }
}

CreatorProduct.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  thumbnailUrl: PropTypes.string.isRequired
};
