import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import InvalidErrorMessage from './InvalidErrorMessage';
import SaleAtPullDownField from './SaleAtPullDownField';

const startAtDisabledKey = 'saleStartAt';
const endAtDisabledKey = 'saleEndAt';
export default class SaleAtField extends React.Component {
  static greaterThanStartAt(startAt, endAt) {
    return endAt.getTime() >= startAt.getTime();
  }

  static formatDateTime(datetime) {
    if (!datetime) return '';

    return moment(datetime).format('YYYY/MM/DD HH:mm');
  }

  static validGreaterThanStartAt(startAt, endAt) {
    if (!startAt || !endAt) return null;

    let startAtDate = startAt;
    let endAtDate = endAt;

    if (typeof (startAtDate) === 'string') startAtDate = moment(startAtDate).toDate();
    if (typeof (endAtDate) === 'string') endAtDate = moment(endAtDate).toDate();

    if (!SaleAtField.greaterThanStartAt(startAtDate, endAtDate)) {
      return '販売終了日時は販売開始日時よりも大きい値を入力してください';
    }

    return null;
  }

  constructor(props) {
    super(props);

    props.setDisabled(startAtDisabledKey, false);
    props.setDisabled(endAtDisabledKey, false);

    const initStartAt = props.saleStartAt || SaleAtField.formatDateTime(new Date());
    const initEntAt = props.saleEndAt || SaleAtField.formatDateTime(new Date());


    this.state = {
      saleStartAt: initStartAt,
      saleEndAt: initEntAt,
      invalidSaleStartAtMessage: null,
      invalidSaleEndAtMessage: SaleAtField.validGreaterThanStartAt(initStartAt, initEntAt)
    };

    this.onChangeSaleStartAtPicker = this.onChangeSaleStartAtPicker.bind(this);
    this.onChangeSaleEndAtPicker = this.onChangeSaleEndAtPicker.bind(this);
  }

  onChangeSaleStartAtPicker(date) {
    let invalidMessage = null;
    if (!date) invalidMessage = '販売開始日時を入力してください';

    this.setState((prevState) => {
      const lessMessage = SaleAtField.validGreaterThanStartAt(date, prevState.saleEndAt);

      const newState = {
        saleStartAt: SaleAtField.formatDateTime(date),
        invalidSaleStartAtMessage: invalidMessage
      };
      newState.invalidSaleEndAtMessage = lessMessage;

      this.props.setDisabled(startAtDisabledKey, Boolean(invalidMessage));
      this.props.setDisabled(endAtDisabledKey, Boolean(lessMessage));

      return newState;
    });
  }

  onChangeSaleEndAtPicker(date) {
    let invalidMessage = null;
    if (!date) {
      invalidMessage = '販売終了日時を入力してください';
    } else if (this.state.saleStartAt && date) {
      invalidMessage = SaleAtField.validGreaterThanStartAt(this.state.saleStartAt, date);
    }

    this.props.setDisabled(endAtDisabledKey, Boolean(invalidMessage));
    this.setState({
      saleEndAt: SaleAtField.formatDateTime(date),
      invalidSaleEndAtMessage: invalidMessage
    });
  }

  render() {
    return (
      <dl>
        <dt>
          <span className="__text">販売期間</span>
          <span className="__caption">SALES PERIOD</span>
        </dt>
        <dd>
          <h4 style={{ color: '#fff' }}>販売開始</h4>
          <div className="schedule__field __first">
            <SaleAtPullDownField
              saleAt={this.state.saleStartAt}
              saleAtFieldName={this.props.saleStartAtFieldName}
              onChange={this.onChangeSaleStartAtPicker}
            />
          </div>
          {this.state.invalidSaleStartAtMessage ? (
            <div className="sale-start-at_validation_error_wrap">
              <InvalidErrorMessage invalidMessage={this.state.invalidSaleStartAtMessage} />
            </div>
          ) : ''}
          <h4 style={{ color: '#fff' }}>販売終了</h4>
          <div className="schedule__field">
            <SaleAtPullDownField
              saleAt={this.state.saleEndAt}
              saleAtFieldName={this.props.saleEndAtFieldName}
              onChange={this.onChangeSaleEndAtPicker}
            />
          </div>
          {this.state.invalidSaleEndAtMessage ? (
            <div className="sale-end-at_validation_error_wrap">
              <InvalidErrorMessage invalidMessage={this.state.invalidSaleEndAtMessage} />
            </div>
          ) : ''}
        </dd>
      </dl>
    );
  }
}

SaleAtField.defaultProps = {
  saleStartAt: '',
  saleEndAt: ''
};
SaleAtField.propTypes = {
  saleStartAt: PropTypes.string,
  saleEndAt: PropTypes.string,
  setDisabled: PropTypes.func.isRequired,
  saleStartAtFieldName: PropTypes.string.isRequired,
  saleEndAtFieldName: PropTypes.string.isRequired
};
