import React from 'react';
import PropTypes from 'prop-types';

export default class ExpiredTimeViewer extends React.Component {
  static fetchCurrentEpochTime() {
    return new Date().getTime();
  }

  constructor(props) {
    super(props);

    this.state = {
      currentEpochTime: ExpiredTimeViewer.fetchCurrentEpochTime()
    };

    setInterval(() => {
      this.setState({ currentEpochTime: ExpiredTimeViewer.fetchCurrentEpochTime() });
    }, 1000);
  }

  calcRemainingTime() {
    let diff = this.props.epochTime - this.state.currentEpochTime;

    const minutes = Math.floor(diff / (1000 * 60));
    diff %= (1000 * 60);

    const seconds = Math.floor(diff / 1000);

    return { minutes, seconds };
  }


  render() {
    const remainingTimes = this.calcRemainingTime();

    return `${remainingTimes.minutes}分${remainingTimes.seconds}秒`;
  }
}

ExpiredTimeViewer.propTypes = {
  epochTime: PropTypes.number.isRequired
};
