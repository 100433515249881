import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = []

  initialize() {
    this.draw_canvas().style.height = (window.innerHeight - 16) + 'px'
  }

  show () {
    this.refocus(event.target);
    this.draw_canvas().innerHTML = event.detail[2].response; // eslint-disable-line
  }

  refocus(target) {
    const class_name = 'table-primary';
    target.closest('table').querySelectorAll('tr[class="' + class_name + '"]').forEach((element) => { // eslint-disable-line
      element.classList.remove(class_name);
    });
    target.closest('tr').classList.add(class_name);
  }

  draw_canvas() {
    return document.getElementById('draw-canvas');
  }
}
