import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import * as lib from 'src/javascripts/concerns'
import { Sortable, OnSpill, AutoScroll } from "sortablejs"


export default class extends Controller {
  static targets = [ 'draggable' ]


  connect() {
    this.buildSortable()
  }

  indexing(block) {
    return this.draggableTargets.forEach((element, index) => {
      element.dataset.index = index
      if (block) {
        block(element)
      }
    })
  }

  bulkUpdate() {
    let ids = new Array;
    this.indexing((element) => {
      ids.push(element.dataset.id)
    })

    let formData = new FormData()
    ids.forEach((id) => {
      formData.append('data[ids][]', id)
    })

    fetch(this.data.get('bulk-update-path'), {
      body: formData,
      method: 'POST',
      credentials: 'include',
      headers: {
        'X-CSRF-Token': Rails.csrfToken()
      }
    }).then(response => response.json())
    .then(data => {
      lib.addToast(data)
    })
  }


  buildSortable() {
    const sortable = new Sortable.create(
      this.element, {
        draggable:      '[data-target="sortable.draggable"],[data-sortable-target="draggable"]',
        handle:         '.handle',
        animation:      200,
        revertOnSpill:  true,
        onUpdate: (event) => {
          this.bulkUpdate()
        }
      }
    )
    return sortable
  }
}
