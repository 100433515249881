const buildDisabledSetter = (instance) => (key, isInvalid) => {
  if (!Object.keys(instance.state.invalidFields).includes(key)) return null;

  instance.setState((prevState) => {
    const currentInvalidFields = prevState.invalidFields;

    currentInvalidFields[key] = isInvalid;
    return {
      invalidFields: currentInvalidFields,
      disabled: Object.values(currentInvalidFields).includes(true)
    };
  });

  return null;
};

export default buildDisabledSetter;
