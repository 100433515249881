import React from 'react';
import PropTypes from 'prop-types';

export default class ColorSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isChecked: this.props.isChecked
    };

    this.onChangeColor = this.onChangeColor.bind(this);
  }

  onChangeColor() {
    this.setState((prevState) => ({ isChecked: !prevState.isChecked }));

    this.props.toggleSelectedColors(
      this.props.id,
      this.props.name,
      this.props.hex,
      !this.state.isChecked
    );
  }

  render() {
    return (
      <li key={this.props.id}>
        <input id={`basic-settings-color-${this.props.id}`} checked={this.state.isChecked} className="__items" type="checkbox" value={this.props.id} name={`${this.props.scope}[color_ids][]`} onChange={this.onChangeColor} />
        <label htmlFor={`basic-settings-color-${this.props.id}`} style={{ backgroundColor: this.props.hex }} className={this.state.isChecked ? 'selected' : ''}>
          <div className="__tooltip">{this.props.name}</div>
        </label>
      </li>
    );
  }
}

ColorSelector.defaultProps = {
  isChecked: false,
  scope: 'basic_settings'
};

ColorSelector.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  hex: PropTypes.string.isRequired,
  toggleSelectedColors: PropTypes.func.isRequired,
  isChecked: PropTypes.bool,
  scope: PropTypes.string
};
