import { Controller } from "stimulus"
import * as lib from 'src/javascripts/concerns'


export default class extends Controller {
  static targets = [ "modal" ]


  connect() {
    this.canvas = document.createElement('div')
    this.canvas.classList.add('modal-canvas')
-
    this.element.appendChild(this.canvas)
  }

  open() {
    this.canvas.innerHTML = event.detail[2].response

    $(this.modalTarget).modal('show')
    $(this.modalTarget).on('hidden.bs.modal', (event) => {
      this.show()
    })
  }

  show() {
    const path = this.data.get('path');
    if (!path) {
      return;
    }

    fetch(path, {
    }).then(
      response => response.text()
    ).then(html => {
      const after = lib.createElementFromHTML(html)
      this.element.parentNode.replaceChild(after, this.canvas)
    })
  }

  close() {
    $(this.modalTarget).modal('hide')
    $(this.modalTarget).modal('dispose')
  }

  redirect() {
    if (!event.detail) {
      return
    }
    if (!event.detail[0].location) {
      return
    }

    window.location.href = event.detail[0].location
  }
}
