import React from 'react';
import PropTypes from 'prop-types';

import RailsCsrfToke from '../shared/RailsCsrfToken';
import ProductNameField from './shared/Forms/ProductNameField';
import PriceField from './shared/Forms/PriceField';
import DescriptionField from './shared/Forms/DescriptionField';
import OpenedField from './shared/Forms/OpenedField';
import SaleAtField from './shared/Forms/SaleAtField';
import SelectedStandardHiddenFields from './ProductsForm/SelectedStandardHiddenFields';

import EnabledColorViewer from './shared/Forms/EnabledColorViewer';
import EnabledSizeViewer from './shared/Forms/EnabledSizeViewer';

import buildDisabledSetter from './shared/buildDisabledSetter';

export default class ProductsForm extends React.Component {
  static toggleSelectedIds(id, ids, isChecked) {
    const index = ids.indexOf(id);

    if (isChecked) {
      if (index === -1) ids.push(id);
    } else if (index !== -1) ids.splice(index, 1);

    return ids;
  }

  constructor(props) {
    super(props);

    /* eslint-disable react/no-unused-state */
    this.state = {
      invalidFields: {
        productName: false,
        price: false,
        description: false,
        saleStartAt: false,
        saleEndAt: false,
        position: false,
        colors: false,
        sizes: false
      },
      disabled: false,
      selectedColorIds: this.props.selectedColorIds,
      selectedSizeIds: this.props.selectedSizeIds,
      selectedPositionCode: this.props.selectedPositionCode
    };
    /* eslint-enable react/no-unused-state */
  }

  formAction() {
    return `/creators/products/${this.props.productId}`;
  }

  render() {
    return (
      <form action={this.formAction()} acceptCharset="UTF-8" method="post">
        <RailsCsrfToke />
        <input type="hidden" value="patch" name="_method" />

        <SelectedStandardHiddenFields fieldName="current_color_ids" ids={this.props.selectedColorIds} />
        <SelectedStandardHiddenFields fieldName="current_size_ids" ids={this.props.selectedSizeIds} />
        <SelectedStandardHiddenFields fieldName="current_position_codes" ids={[this.props.selectedPositionCode]} />

        <div className="box__wt item__configurator design__image__setter">
          <div className="item__configurator__body">
            <ProductNameField
              productName={this.props.productName}
              setDisabled={buildDisabledSetter(this)}
              isInvalid={this.props.isInvalid}
              fieldName="product[name]"
            />
            <PriceField
              price={this.props.price}
              maxVariationPrice={this.props.maxVariationPrice}
              profits={this.props.profits}
              setDisabled={buildDisabledSetter(this)}
              isInvalid={this.props.isInvalid}
              fieldName="product[price]"
            />
            <DescriptionField
              description={this.props.description}
              setDisabled={buildDisabledSetter(this)}
              isInvalid={this.props.isInvalid}
              fieldName="product[description]"
            />
            <OpenedField
              isOpened={this.props.isOpened}
              setDisabled={buildDisabledSetter(this)}
              isInvalid={this.props.isInvalid}
              fieldName="product[state]"
            />
            <SaleAtField
              saleStartAt={this.props.saleStartAt}
              saleEndAt={this.props.saleEndAt}
              setDisabled={buildDisabledSetter(this)}
              saleStartAtFieldName="product[sale_start_at]"
              saleEndAtFieldName="product[sale_end_at]"
            />

            <EnabledColorViewer
              colors={this.props.colors}
              selectedColorIds={this.state.selectedColorIds}
              scope="goods_editor"
            />
            <EnabledSizeViewer
              sizes={this.props.sizes}
              selectedSizeIds={this.state.selectedSizeIds}
              sizeDataImageUrl={this.props.sizeDataImageUrl}
              scope="goods_editor"
            />

            <div className="form__action">
              <ul>
                <li>
                  <a className="btn__default" href="/">トップに戻る</a>
                </li>
                <li>
                  <input type="submit" name="commit" value="保存する" className="btn__edit __normal" id="product-setting-submit" disabled={this.state.disabled ? 'disabled' : ''} />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

ProductsForm.defaultProps = {
  isInvalid: false
};

ProductsForm.propTypes = {
  productId: PropTypes.number.isRequired,
  maxVariationPrice: PropTypes.number.isRequired,
  profits: PropTypes.arrayOf(PropTypes.object).isRequired,
  colors: PropTypes.arrayOf(PropTypes.object).isRequired,
  sizes: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedColorIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedSizeIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedPositionCode: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  isOpened: PropTypes.bool.isRequired,
  saleStartAt: PropTypes.string.isRequired,
  saleEndAt: PropTypes.string.isRequired,
  isInvalid: PropTypes.bool,
  sizeDataImageUrl: PropTypes.string.isRequired
};
