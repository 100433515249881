import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = []

  show () {
    this.refocus(event.target);
    document.getElementById('draw-canvas').innerHTML = event.detail[2].response; // eslint-disable-line
  }

  refocus(target) {
    const class_name = 'table-primary';
    target.closest('table').querySelectorAll('tr[class="' + class_name + '"]').forEach((element) => { // eslint-disable-line
      element.classList.remove(class_name);
    });
    target.closest('tr').classList.add(class_name);
  }
}
