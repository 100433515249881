import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [
    'mediumCanvas', 'smallCanvas',
    'largeSelector', 'mediumSelector', 'smallSelector'
  ]
  static values = {
    mediumId: String,
    smallId: String
  }

  connect() {
    this.loadMediumOptions()
  }

  loadMediumOptions() {
    let query = new URLSearchParams({
      parent_id: this.largeSelectorTarget.value,
      current_id: this.mediumIdValue
    })

    fetch(`${this.largeSelectorTarget.dataset.path}?${query}`, {
    }).then(response => response.text()
    ).then(html => {
      this.mediumCanvasTarget.innerHTML = html
    }).then(() => {
      this.loadSmallOptions()
      this.mediumIdValue = this.mediumSelectorTarget.value
    })
  }

  loadSmallOptions() {
    let query = new URLSearchParams({
      parent_id: this.mediumSelectorTarget.value,
      current_id: this.smallIdValue
    })

    fetch(`${this.mediumSelectorTarget.dataset.path}?${query}`, {
    }).then(response => response.text()
    ).then(html => {
      this.smallCanvasTarget.innerHTML = html
    }).then(() => {
      this.smallIdValue = this.smallSelectorTarget.value
    })
  }
}
