import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import * as lib from 'src/javascripts/concerns'

export default class extends Controller {
  static targets = [ 'checks', 'check', 'registerArea', 'selectedNumber' ]

  connect() {
    this.updateChecks()
    this.refreshMasterProducts()
  }

  refreshMasterProducts() {
    fetch(this.buildURL(), {
    })
    .then(response => response.json()
    ).then(master_products => {
      this.checkTargets.map(check => {
        let tr = check.closest('tr')
        let master_product = master_products.find(master_product => tr.dataset.id == master_product.id )
        if (!master_product) {
          return
        }

        // state
        let href =  check.closest('tr').querySelector('[data-state]')

        let state_class = ''
        let state_text = ''

        switch(master_product.state) {
          case 'opened':
            state_class = 'badge-success'
            state_text = '公開'
            break;
          case 'limited':
            state_class = 'badge-warning'
            state_text = '社内限定公開'
            break;
          default:
            state_class = 'badge-danger'
            state_text = '非公開'
        }

        href.classList.remove('badge-success')
        href.classList.remove('badge-warning')
        href.classList.remove('badge-danger')
        href.classList.add(state_class)
        href.innerText = state_text
      })
    })
  }

  toggleChecks() {
    this.checkTargets.forEach(check => {
      check.checked = this.checksTarget.checked
    })

    this.renderSelectbox()
  }

  updateChecks() {
    switch(this.checkedElements().length) {
      case 0:
        this.checksTarget.indeterminate = false
        this.checksTarget.checked       = false
        break;
      case this.checkTargets.length:
        this.checksTarget.indeterminate = false
        this.checksTarget.checked       = true
        break;
      default:
        this.checksTarget.indeterminate = true
    }

    this.renderSelectbox()
  }

  renderSelectbox() {
    if (this.checkedElements().length > 0) {
      this.registerAreaTarget.style.display = 'block'
    } else {
      this.registerAreaTarget.style.display = 'none'
    }
    this.selectedNumberTarget.innerText = this.checkedElements().length
  }

  reflect() {
    let params = []

    this.checkedElements().map(check => {
      return check.value
    }).forEach(id => {
      params.push({ name: 'master_product_ids[]', value: id })
    })

    event.currentTarget.setAttribute('data-params', lib.serializeParams(params))
  }

  checkedElements() {
    return this.checkTargets.filter(check => {
      return check.checked
    })
  }

  buildURL() {
    let ids = this.checkTargets.map(check => {
      return check.closest('tr').dataset.id
    }).join(',')

    return '/admin/master_products.json?ids=' + ids
  }
}
