import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ 'canvas' ]
  static values = {
  }

  connect() {
  }

  display() {
    const option = event.target.selectedOptions[0]

    console.log(option)


    fetch(option.dataset.path)
      .then((response) => {
        switch (response.status) {
          case 200:
            this.render(response.text());
            break;
          case 204:
            this.render('');
            break;
          bread:
            break;
        }
      })
  }

  async render(html) {
    this.canvasTarget.innerHTML = ''
    this.canvasTarget.insertAdjacentHTML('afterbegin', await html)
  }
}
