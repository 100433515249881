import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import * as lib from 'src/javascripts/concerns'

export default class extends Controller {
  static targets = [ 'checks', 'check', 'registerArea', 'selectedNumber' ]

  connect() {
    this.updateChecks()
    this.refreshProducts()
  }

  refreshProducts() {
    fetch(this.buildURL(), {
    })
    .then(response => response.json()
    ).then(products => {
      this.checkTargets.map(check => {
        let tr = check.closest('tr')
        let product = products.find(product => tr.dataset.id == product.id )
        if (!product) {
          return
        }

        // state
        let href =  check.closest('tr').querySelector('[data-state]')

        let state_class = product.state == 'opened' ? 'badge-success' : 'badge-danger'
        let state_text = product.state == 'opened' ? '公開' : '非公開'

        href.classList.remove('badge-success')
        href.classList.remove('badge-danger')
        href.classList.add(state_class)
        href.innerText = state_text
      })
    })
  }

  toggleChecks() {
    this.checkTargets.forEach(check => {
      check.checked = this.checksTarget.checked
    })

    this.renderSelectbox()
  }

  updateChecks() {
    switch(this.checkedElements().length) {
      case 0:
        this.checksTarget.indeterminate = false
        this.checksTarget.checked       = false
        break;
      case this.checkTargets.length:
        this.checksTarget.indeterminate = false
        this.checksTarget.checked       = true
        break;
      default:
        this.checksTarget.indeterminate = true
    }

    this.renderSelectbox()
  }

  renderSelectbox() {
    if (this.checkedElements().length > 0) {
      this.registerAreaTarget.style.display = 'block'
    } else {
      this.registerAreaTarget.style.display = 'none'
    }
    this.selectedNumberTarget.innerText = this.checkedElements().length
  }

  reflect() {
    let params = []

    this.checkedElements().map(check => {
      return check.value
    }).forEach(id => {
      params.push({ name: 'product_ids[]', value: id })
    })

    event.currentTarget.setAttribute('data-params', lib.serializeParams(params))
  }

  checkedElements() {
    return this.checkTargets.filter(check => {
      return check.checked
    })
  }

  buildURL() {
    let ids = this.checkTargets.map(check => {
      return check.closest('tr').dataset.id
    }).join(',')

    return '/admin/products.json?ids=' + ids
  }
}
