import React from 'react';

export default class HelpTooltip extends React.Component {
  static MAX_SP_WIDTH = 789;

  static HELP_MESSAGE = '指定した期間の合計注文金額（キャンセル含む）です。※ クリエイターさまへの売上振込とは異なります';

  static SHOWABLE_CLASS_NAME = 'is-show';

  static toggleTooltipClass(classList) {
    const showableIndex = this.showableClassIndex(classList);
    if (showableIndex === -1) {
      classList.push(HelpTooltip.SHOWABLE_CLASS_NAME);
    } else {
      classList.splice(showableIndex, 1);
    }

    return classList;
  }

  static showableClassIndex(classList) {
    return classList.indexOf(HelpTooltip.SHOWABLE_CLASS_NAME);
  }

  static buildClassName(classList) {
    return classList.join(' ');
  }

  constructor(props) {
    super(props);

    this.state = {
      pcClassList: ['pc__help__tooltip'],
      spClassList: ['sp__help__tooltip']
    };

    this.handleHelpTooltip = this.handleHelpTooltip.bind(this);
  }

  handleHelpTooltip() {
    this.setState((prevState) => {
      if (window.outerWidth < HelpTooltip.MAX_SP_WIDTH) {
        return { spClassList: HelpTooltip.toggleTooltipClass(prevState.spClassList) };
      }
      return { pcClassList: HelpTooltip.toggleTooltipClass(prevState.pcClassList) };
    });
  }

  pcClassName() {
    return HelpTooltip.buildClassName(this.state.pcClassList);
  }

  spClassName() {
    return HelpTooltip.buildClassName(this.state.spClassList);
  }

  render() {
    return (
      <>
        <div className="help__text" onClick={this.handleHelpTooltip}>?</div>
        <p className={this.pcClassName()}>
          <span className="help__inner">{HelpTooltip.HELP_MESSAGE}</span>
        </p>
        <div className={this.spClassName()}>
          <div className="help__inner">
            {HelpTooltip.HELP_MESSAGE}
            <button type="button" className="button__tooltip__close" onClick={this.handleHelpTooltip}>
              閉じる
            </button>
          </div>
        </div>
      </>
    );
  }
}
