import React from 'react';
import PropTypes from 'prop-types';

export default class SizeDataModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
    this.openSizeDataImageModal = this.openSizeDataImageModal.bind(this);
    this.closeSizeDataImageModal = this.closeSizeDataImageModal.bind(this);
  }

  openSizeDataImageModal() {
    this.setState({ isOpen: true });
  }

  closeSizeDataImageModal() {
    this.setState({ isOpen: false });
  }

  render() {
    if (!this.props.sizeDataImageUrl.length) return <></>;

    return (
      <>
        <button className="size__table__view" onClick={this.openSizeDataImageModal} type="button">サイズ表を見る</button>
        <div className="size__table" id="modal__ondemand" style={{ display: this.state.isOpen ? 'block' : 'none' }} onClick={this.closeSizeDataImageModal}>
          <section className="__window">
            <span className="__close" />

            <img src={this.props.sizeDataImageUrl} alt="規格画像" />
          </section>
        </div>
      </>
    );
  }
}

SizeDataModal.propTypes = {
  sizeDataImageUrl: PropTypes.string.isRequired
};
