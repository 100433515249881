import React from 'react';
import PropTypes from 'prop-types';

import FetchJobStatus from '../../utills/FetchJobStatus';
import Spinner from '../shared/Spinner';

const INVALID_ERROR_MESSAGE = '画像の登録に失敗しました';
export default class UploadImagePolling extends React.Component {
  static async pollingSleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  constructor(props) {
    super(props);

    this.state = {
      isJobWorking: true,
      errorMessage: null,
      isSpinnerShow: true
    };
  }

  async componentDidMount() {
    await this.checkUploadImageBuilderWorking(this.props.jobId);
  }

  async componentDidUpdate() {
    if (this.state.isJobWorking) {
      await UploadImagePolling.pollingSleep(1000);
      await this.checkUploadImageBuilderWorking(this.props.jobId);
    } else if (this.state.errorMessage) {
      window.alert(this.state.errorMessage);
    } else {
      window.location.href = this.props.redirectUrl;
    }
  }

  async checkUploadImageBuilderWorking(jobId) {
    const status = await FetchJobStatus(jobId);

    switch (status) {
      case 'complete':
        this.setState({ isJobWorking: false });
        break;
      case 'failed':
        this.setState({
          isJobWorking: false,
          errorMessage: INVALID_ERROR_MESSAGE,
          isSpinnerShow: false
        });
        break;
      default:
        this.setState({ isJobWorking: true });
        break;
    }
  }

  render() {
    return <Spinner isShow={this.state.isSpinnerShow} />;
  }
}

UploadImagePolling.propTypes = {
  jobId: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string.isRequired
};
