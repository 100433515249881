import React from 'react';
import PropTypes from 'prop-types';

const ProfitViewer = (props) => (
  <li>
    <dl>
      <dt>{`${props.colorName} ${props.sizeName}`}</dt>
      <dd>{`¥${props.profit.toLocaleString()}`}</dd>
    </dl>
  </li>
);

ProfitViewer.propTypes = {
  profit: PropTypes.number.isRequired,
  colorName: PropTypes.string.isRequired,
  sizeName: PropTypes.string.isRequired
};

export default ProfitViewer;
