import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = []

  static values = {
  }

  initialize() {
    this.query((radio) => {
      if (radio.checked) {
        radio.nextElementSibling.classList.add('selected')
      }
    })
  }

  labelling() {
    this.query((radio) => {
      radio.nextElementSibling.classList.remove('selected')
    })
    event.target.classList.add('selected')
  }

  query(func) {
    this.element.querySelectorAll('input[type="radio"]').forEach(radio => {
      func(radio)
    })
  }
}
