import React from 'react';
import PropTypes from 'prop-types';

import TwitterIcon from '../../../images/ondemand/icon-twitter.wt.svg';
import InstagramIcon from '../../../images/ondemand/icon-instagram.wt.svg';
import FacebookIcon from '../../../images/ondemand/icon-facebook.wt.svg';
import LineIcon from '../../../images/ondemand/icon-line.wt.svg';
import YoutubeIcon from '../../../images/ondemand/icon-youtube.png';
import TiktokIcon from '../../../images/ondemand/icon-tiktok.png';

export default class RelatedLinkViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deleted: false
    };

    this.iconRef = React.createRef();

    this.onClickDeletable = this.onClickDeletable.bind(this);
  }

  componentDidMount() {
    if (this.props.imageValue) {
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(this.props.imageValue);
      this.iconRef.current.files = dataTransfer.files;
    }
  }

  onClickDeletable() {
    if (window.confirm('この処理は、取り消すことができません。本当に削除しますか？')) {
      this.setState({ deleted: true });
    }
  }

  relatedLinkIcon() {
    if (this.props.image) {
      return <figure className="icon_related" style={{ backgroundImage: `url(${this.props.image})` }} />;
    }

    let cssClass = '';
    let serviceIcon = '';
    switch (this.props.service) {
      case 'twitter':
        cssClass = 'icon__tw profile_img';
        serviceIcon = TwitterIcon;
        break;
      case 'facebook':
        cssClass = 'icon__FB profile_img';
        serviceIcon = FacebookIcon;
        break;
      case 'instagram':
        cssClass = 'icon__instagram profile_img';
        serviceIcon = InstagramIcon;
        break;
      case 'line':
        cssClass = 'icon__line profile_img';
        serviceIcon = LineIcon;
        break;
      case 'tiktok':
        cssClass = 'icon__tiktok profile_img';
        serviceIcon = TiktokIcon;
        break;
      case 'youtube':
        cssClass = 'icon__youtube profile_img';
        serviceIcon = YoutubeIcon;
        break;
      default:
        return <figure className="icon_related" />;
    }

    return <figure className={cssClass} style={{ backgroundImage: `url(${serviceIcon})` }} />;
  }

  render() {
    if (this.state.deleted) return '';

    return (
      <li>
        <article>
          {this.relatedLinkIcon()}
          <h3>
            {this.props.title}
            <span className="url_text">{this.props.link}</span>
          </h3>
        </article>
        <p className="remove_link">
          <span onClick={this.onClickDeletable}>この項目を削除する</span>
        </p>
        <input type="hidden" name="creator[related_links][][service]" value={this.props.service} />
        <input type="hidden" name="creator[related_links][][title]" value={this.props.title} />
        <input type="hidden" name="creator[related_links][][link]" value={this.props.link} />
        {this.props.imageValue ? (
          <input type="file" name="creator[related_links][][image]" style={{ display: 'none' }} ref={this.iconRef} />
        ) : (
          <input type="hidden" name="creator[related_links][][image]" value={this.props.image} />
        ) }
      </li>
    );
  }
}

RelatedLinkViewer.defaultProps = {
  image: '',
  imageValue: null
};

RelatedLinkViewer.propTypes = {
  service: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  imageValue: PropTypes.objectOf(PropTypes.any)
};
