import React from 'react';
import PropTypes from 'prop-types';

import RailsCsrfToke from '../shared/RailsCsrfToken';
import SelectedStandard from './shared/SelectedStandard';
import PrintingImage from './DesignSettingsForm/PrintingImage';

export default class DesignSettingsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedImage: [],
      disabled: true
    };

    this.addSelectedImages = this.addSelectedImages.bind(this);
  }

  setDisabled(selectedImages) {
    const disabled = selectedImages.length !== this.props.printings.length;

    this.setState({ disabled });
  }

  addSelectedImages(printingCode) {
    this.setState((prevState) => {
      const currentSelected = prevState.selectedImage;

      if (currentSelected.includes(printingCode)) return prevState;

      currentSelected.push(printingCode);

      this.setDisabled(currentSelected);
      return { selectedImage: currentSelected };
    });
  }

  formAction() {
    return `/creators/master_products/${this.props.masterProductId}/design_settings`;
  }

  prevButtonHref() {
    return `/creators/master_products/${this.props.masterProductId}/basic_settings`;
  }

  render() {
    return (
      <form action={this.formAction()} acceptCharset="UTF-8" method="post" encType="multipart/form-data" data-remote>
        <RailsCsrfToke />

        <div className="box__wt item__configurator design__image__setter">
          {this.props.printings.map((printing) => (
            <PrintingImage
              key={printing.code}
              positionName={printing.name}
              positionCode={printing.code}
              printHeightPx={printing.print_height_px}
              printWidthPx={printing.print_width_px}
              printingImageUrl={this.props.positionImageUrls[printing.code]}
              addSelectedImages={this.addSelectedImages}
            />
          ))}
          <div className="item__configurator__body">
            <div className="seleted__parameters">
              <h3>選択された項目</h3>
              <div className="title__caption">DESIGN ITEM</div>
              <dl>
                <dt>選択したカラー</dt>
                <dd>
                  <ul>
                    {this.props.colors.map((color) => (
                      <SelectedStandard
                        key={color.id}
                        id={color.id}
                        name={color.name}
                        hex={color.hex}
                      />
                    ))}
                  </ul>
                </dd>
              </dl>
              <dl>
                <dt>選択したサイズ</dt>
                <dd>
                  <ul>
                    {this.props.sizes.map((size) => (
                      <SelectedStandard
                        key={size.id}
                        id={size.id}
                        name={size.name}
                      />
                    ))}
                  </ul>
                </dd>
              </dl>
              <dl>
                <dt>選択した配置</dt>
                <dd>
                  <ul>
                    <SelectedStandard
                      id={1}
                      name={this.props.positionName}
                    />
                  </ul>
                </dd>
              </dl>
            </div>
            <div className="form__action">
              <ul>
                <li>
                  <a className="btn__default" href={this.prevButtonHref()}>一つ前に戻る</a>
                </li>
                <li>
                  <input type="submit" name="commit" value="デザイン確認にすすむ" className="btn__edit __normal" id="design-setting-submit" disabled={this.state.disabled ? 'disabled' : ''} />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

DesignSettingsForm.propTypes = {
  masterProductId: PropTypes.number.isRequired,
  colors: PropTypes.arrayOf(PropTypes.object).isRequired,
  sizes: PropTypes.arrayOf(PropTypes.object).isRequired,
  printings: PropTypes.arrayOf(PropTypes.object).isRequired,
  positionName: PropTypes.string.isRequired,
  positionImageUrls: PropTypes.objectOf(PropTypes.string).isRequired
};
