import React from 'react';
import PropTypes from 'prop-types';

export default class Spinner extends React.Component {
  render() {
    if (!this.props.isShow) return '';

    return (
      <div id="design-setting-spinner">
        <div className="spinner-base">
          <div className="spinner">
            <span className="ball1" />
            <span className="ball2" />
          </div>
        </div>
      </div>
    );
  }
}

Spinner.propTypes = {
  isShow: PropTypes.bool.isRequired
};
