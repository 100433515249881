import React from 'react';
import PropTypes from 'prop-types';

import ColorSelector from './ColorSelector';

const EnabledColorViewer = (props) => (
  <dl>
    <dt>
      <span className="__text">カラーを選択</span>
      <span className="__caption">COLOR</span>
    </dt>
    <dd>
      <h4>ご希望のカラーにチェックを入れてください(複数選択可能)</h4>
      <ul className="action__checkbox__switch color__list">
        {props.colors.map((color) => (
          <ColorSelector
            key={color.id}
            id={color.id}
            name={color.name}
            hex={color.hex}
            toggleSelectedColors={props.toggleSelectedColors}
            isChecked={props.selectedColorIds.includes(color.id)}
            scope={props.scope}
          />
        ))}
      </ul>
    </dd>
  </dl>
);

EnabledColorViewer.defaultProps = {
  toggleSelectedColors: () => { /* 何もしない */ },
  selectedColorIds: [],
  scope: 'basic_settings'
};

EnabledColorViewer.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.object).isRequired,
  toggleSelectedColors: PropTypes.func,
  selectedColorIds: PropTypes.arrayOf(PropTypes.number),
  scope: PropTypes.string
};

export default EnabledColorViewer;
