import { Controller } from 'stimulus';


export default class extends Controller {
  static targets = []

  show() {
    document.getElementById('draw-canvas').innerHTML = event.detail[2].response; // eslint-disable-line
  }
}
