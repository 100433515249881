// Load all the controllers within this directory and all subdirectories.-
// Controller files must be named *_controller.js.

import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import ReadMore from 'stimulus-read-more';
import Flatpickr from 'stimulus-flatpickr';
import Clipboard from 'stimulus-clipboard'

const application = Application.start();
const context = require.context('controllers', true, /_controller\.js$/);
application.load(definitionsFromContext(context));

import 'flatpickr/dist/themes/airbnb.css';

import { Japan } from "flatpickr/dist/l10n/ja.js";
export default class ExtendedFlatpickr extends Flatpickr {
  initialize() {
    this.config = {
      locale: 'ja'
    };
  }
}

application.register('read-more', ReadMore);
application.register('flatpickr', ExtendedFlatpickr);
application.register('clipboard', Clipboard)
