import 'whatwg-fetch';

const jobStatusPath = (jobId) => `/job_statuses/${jobId}`;

const FetchJobStatus = async (jobId) => {
  const path = jobStatusPath(jobId);

  const response = await fetch(path, {
    mode: 'cors',
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    }
  });

  if (response.status !== 200) return null;

  const json = await response.json();

  return json.status;
};

export default FetchJobStatus;
