import React from 'react';
import PropTypes from 'prop-types';

import SizeSelector from './SizeSelector';
import SizeDataModal from '../SizeDataModal';

const EnabledSizeViewer = (props) => (
  <dl>
    <dt>
      <span className="__text">サイズを選択</span>
      <span className="__caption">SIZE</span>
    </dt>
    <dd>
      <h4>ご希望のサイズにチェックを入れてください(複数選択可能)</h4>
      <ul className="action__checkbox__switch size__list">
        {props.sizes.map((size) => (
          <SizeSelector
            key={size.id}
            id={size.id}
            name={size.name}
            toggleSelectedSizes={props.toggleSelectedSizes}
            isChecked={props.selectedSizeIds.includes(size.id)}
            scope={props.scope}
          />
        ))}
      </ul>

      <SizeDataModal sizeDataImageUrl={props.sizeDataImageUrl} />
    </dd>
  </dl>
);

EnabledSizeViewer.defaultProps = {
  toggleSelectedSizes: () => { /* 何もしない */ },
  selectedSizeIds: [],
  sizeDataImageUrl: '',
  scope: 'basic_settings'
};

EnabledSizeViewer.propTypes = {
  sizes: PropTypes.arrayOf(PropTypes.object).isRequired,
  toggleSelectedSizes: PropTypes.func,
  selectedSizeIds: PropTypes.arrayOf(PropTypes.number),
  sizeDataImageUrl: PropTypes.string,
  scope: PropTypes.string
};

export default EnabledSizeViewer;
