import React from 'react';
import PropTypes from 'prop-types';

const CheckedClassName = 'selected';
export default class OpenedField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpened: this.props.isOpened
    };

    this.onChangeIsOpened = this.onChangeIsOpened.bind(this);
  }

  onChangeIsOpened(e) {
    this.setState({ isOpened: e.target.value === 'true' });
  }

  render() {
    return (
      <dl>
        <dt>
          <span className="__text">公開 / 非公開</span>
          <span className="__caption">RELEASE</span>
        </dt>
        <dd>
          <ul className="switch__list __two_cols">
            <li>
              <label
                className={this.state.isOpened ? CheckedClassName : ''}
                htmlFor="product_settings_is_opened_true"
              >
                <input
                  className="__items"
                  type="radio"
                  value="true"
                  checked={this.state.isOpened}
                  name={this.props.fieldName}
                  id="product_settings_is_opened_true"
                  onChange={this.onChangeIsOpened}
                />
                公開
              </label>
            </li>
            <li>
              <label
                className={!this.state.isOpened ? CheckedClassName : ''}
                htmlFor="product_settings_is_opened_false"
              >
                <input
                  className="__items"
                  type="radio"
                  value="false"
                  checked={!this.state.isOpened}
                  name={this.props.fieldName}
                  id="product_settings_is_opened_false"
                  onChange={this.onChangeIsOpened}
                />
                非公開
              </label>
            </li>
          </ul>
        </dd>
      </dl>
    );
  }
}

OpenedField.defaultProps = {
  isOpened: true
};
OpenedField.propTypes = {
  isOpened: PropTypes.bool,
  fieldName: PropTypes.string.isRequired
};
