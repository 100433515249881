import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import * as lib from 'src/javascripts/concerns'

export default class extends Controller {
  static targets = [ ]

  connect() {
    this.boundUpdateMethod = this.update.bind(this)
    this.element.addEventListener('change', this.boundUpdateMethod)

    this.update()
  }

  disconnect() {
    this.element.removeEventListener('change', this.boundUpdateMethod)
  }

  update() {
    const label = this.element.nextElementSibling
    if (this.element.files[0]) {
      label.style.backgroundColor = '#ffffff'
    } else {
      label.style.backgroundColor = '#ffc107'
    }
  }
}
