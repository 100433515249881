import React from 'react';
import PropTypes from 'prop-types';

import InvalidErrorMessage from './InvalidErrorMessage';
import FORM_ERROR_CLASS_NAME from './FormErrorClassName';

const disabledKey = 'productName';
const blankErrorMessage = 'アイテムの名前を入力してください';

export default class ProductNameField extends React.Component {
  constructor(props) {
    super(props);

    props.setDisabled(disabledKey, !props.productName);

    this.state = {
      productName: props.productName || '',
      invalidProductNameMessage: props.isInvalid && !props.productName ? blankErrorMessage : null
    };

    this.onchangeProductName = this.onchangeProductName.bind(this);
  }

  onchangeProductName(e) {
    const insertedProductName = e.target.value;

    let invalidMessage = null;
    if (!insertedProductName) invalidMessage = blankErrorMessage;

    this.props.setDisabled(disabledKey, Boolean(invalidMessage));
    this.setState({ productName: insertedProductName, invalidProductNameMessage: invalidMessage });
  }

  render() {
    return (
      <dl>
        <dt>
          <span className="__text">アイテムの名前</span>
          <span className="__caption">ITEM NAME</span>
        </dt>
        <dd>
          <h4>アイテムの名前を入力してください。</h4>
          <input
            type="text"
            name={this.props.fieldName}
            id="product_settings_product_name"
            value={this.state.productName}
            onChange={this.onchangeProductName}
            className={this.state.invalidProductNameMessage ? FORM_ERROR_CLASS_NAME : ''}
          />
          <div className="name_validation_error_wrap">
            {this.state.invalidProductNameMessage ? (<InvalidErrorMessage invalidMessage={this.state.invalidProductNameMessage} />) : ''}
          </div>
        </dd>
      </dl>
    );
  }
}

ProductNameField.defaultProps = {
  productName: '',
  isInvalid: false
};
ProductNameField.propTypes = {
  productName: PropTypes.string,
  setDisabled: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool,
  fieldName: PropTypes.string.isRequired
};
