import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import zeroPadding from '../../../utills/zeroPadding';

export default class SaleAtPullDownField extends React.Component {
  static buildYearOptions() {
    const currentYear = new Date().getFullYear();

    return this.buildOptions(-5, 5, currentYear, false);
  }

  static buildMonthOptions() {
    return this.buildOptions(1, 12);
  }

  static buildDayOptions() {
    return this.buildOptions(1, 31);
  }

  static buildHourOptions() {
    return this.buildOptions(0, 23);
  }

  static buildMinuteOptions() {
    return this.buildOptions(0, 59);
  }

  static buildOptions(start, end, baseValue = 0, isZeroPadding = true) {
    const options = [];
    for (let i = start; i <= end; i += 1) {
      const optionValue = baseValue + i;
      const optionLabel = isZeroPadding ? zeroPadding(optionValue, 2) : optionValue;
      options.push(<option value={optionValue} key={i}>{optionLabel}</option>);
    }
    return options;
  }

  static formatDateTime(datetime) {
    if (!datetime) return '';

    return moment(datetime).format('YYYY/MM/DD HH:mm');
  }

  constructor(props) {
    super(props);

    let initSaleAt;
    if (props.saleAt && props.saleAt.length) {
      initSaleAt = new Date(props.saleAt);
    } else {
      initSaleAt = new Date();
    }

    this.yearRef = React.createRef();
    this.monthRef = React.createRef();
    this.dayRef = React.createRef();
    this.hourRef = React.createRef();
    this.minuteRef = React.createRef();

    this.state = {
      saleAt: initSaleAt,
      saleAtString: SaleAtPullDownField.formatDateTime(initSaleAt)
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  onChangeHandler() {
    const year = this.yearRef.current.value;
    const month = this.monthRef.current.value;
    const day = this.dayRef.current.value;
    const hour = this.hourRef.current.value;
    const minute = this.minuteRef.current.value;

    const dateInstance = new Date(year, month - 1, day, hour, minute);

    this.setState({
      saleAt: dateInstance,
      saleAtString: SaleAtPullDownField.formatDateTime(dateInstance)
    });

    this.props.onChange(dateInstance);
  }

  render() {
    return (
      <>
        <select name="year" className="__sale-pulldown-year" ref={this.yearRef} onChange={this.onChangeHandler} defaultValue={this.state.saleAt.getFullYear()}>
          $
          {SaleAtPullDownField.buildYearOptions()}
        </select>
        <select name="month" className="__sale-pulldown-month" ref={this.monthRef} onChange={this.onChangeHandler} defaultValue={this.state.saleAt.getMonth() + 1}>
          $
          {SaleAtPullDownField.buildMonthOptions()}
        </select>
        <select name="day" className="__sale-pulldown-day" ref={this.dayRef} onChange={this.onChangeHandler} defaultValue={this.state.saleAt.getDate()}>
          $
          {SaleAtPullDownField.buildDayOptions()}
        </select>
        —
        <select name="hour" className="__sale-pulldown-hour" ref={this.hourRef} onChange={this.onChangeHandler} defaultValue={this.state.saleAt.getHours()}>
          $
          {SaleAtPullDownField.buildHourOptions()}
        </select>
        :
        <select name="minute" className="__sale-pulldown-minute" ref={this.minuteRef} onChange={this.onChangeHandler} defaultValue={this.state.saleAt.getMinutes()}>
          $
          {SaleAtPullDownField.buildMinuteOptions()}
        </select>
        <input type="hidden" name={this.props.saleAtFieldName} value={this.state.saleAtString} />
      </>
    );
  }
}

SaleAtPullDownField.defaultProps = {
  saleAt: '',
  onChange: () => { /* 何もしない */ }
};
SaleAtPullDownField.propTypes = {
  saleAt: PropTypes.string,
  onChange: PropTypes.func,
  saleAtFieldName: PropTypes.string.isRequired
};
