import React from 'react';
import PropTypes from 'prop-types';

import RailsCsrfToke from '../shared/RailsCsrfToken';

import ProductNameField from './shared/Forms/ProductNameField';
import PriceField from './shared/Forms/PriceField';
import DescriptionField from './shared/Forms/DescriptionField';
import OpenedField from './shared/Forms/OpenedField';
import SaleAtField from './shared/Forms/SaleAtField';

import buildDisabledSetter from './shared/buildDisabledSetter';

export default class ProductSettingsForm extends React.Component {
  constructor(props) {
    super(props);

    /* eslint-disable react/no-unused-state */
    this.state = {
      invalidFields: {
        productName: true, price: true, description: true, saleStartAt: true, saleEndAt: true
      },
      disabled: true
    };
    /* eslint-enable react/no-unused-state */
  }

  formAction() {
    return `/creators/master_products/${this.props.masterProductId}/product_settings`;
  }

  prevButtonLink() {
    return `/creators/master_products/${this.props.masterProductId}/design_confirmations`;
  }

  render() {
    return (
      <form action={this.formAction()} acceptCharset="UTF-8" method="post">
        <RailsCsrfToke />

        <div className="box__wt item__configurator design__image__setter">
          <div className="item__configurator__body">
            <ProductNameField
              productName={this.props.productName}
              setDisabled={buildDisabledSetter(this)}
              isInvalid={this.props.isInvalid}
              fieldName="product_settings[product_name]"
            />
            <PriceField
              price={this.props.price}
              maxVariationPrice={this.props.maxVariationPrice}
              profits={this.props.profits}
              setDisabled={buildDisabledSetter(this)}
              isInvalid={this.props.isInvalid}
              fieldName="product_settings[price]"
            />
            <DescriptionField
              description={this.props.description}
              setDisabled={buildDisabledSetter(this)}
              isInvalid={this.props.isInvalid}
              fieldName="product_settings[description]"
            />
            <OpenedField
              isOpened={this.props.isOpened}
              setDisabled={buildDisabledSetter(this)}
              isInvalid={this.props.isInvalid}
              fieldName="product_settings[is_opened]"
            />
            <SaleAtField
              saleStartAt={this.props.saleStartAt}
              saleEndAt={this.props.saleEndAt}
              setDisabled={buildDisabledSetter(this)}
              saleStartAtFieldName="product_settings[sale_start_at]"
              saleEndAtFieldName="product_settings[sale_end_at]"
            />

            <div className="form__action">
              <ul>
                <li>
                  <a className="btn__default" href={this.prevButtonLink()}>一つ前に戻る</a>
                </li>
                <li>
                  <input type="submit" name="commit" value="確認画面にすすむ" className="btn__edit __normal" id="product-setting-submit" disabled={this.state.disabled ? 'disabled' : ''} />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

ProductSettingsForm.defaultProps = {
  productName: '',
  description: '',
  price: 0,
  isOpened: true,
  saleStartAt: '',
  saleEndAt: '',
  isInvalid: false
};

ProductSettingsForm.propTypes = {
  masterProductId: PropTypes.number.isRequired,
  maxVariationPrice: PropTypes.number.isRequired,
  profits: PropTypes.arrayOf(PropTypes.object).isRequired,
  productName: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.number,
  isOpened: PropTypes.bool,
  saleStartAt: PropTypes.string,
  saleEndAt: PropTypes.string,
  isInvalid: PropTypes.bool
};
