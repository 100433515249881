import { Controller } from "stimulus"

export default class extends Controller {
  /*
   *  dataset data-navs-activator-*
   *    matcher   default `start'       `equal` or `start`
   *    selector  default `a.nav-link`  target nodes css selector.
  */
  connect() {
    this.selector = 'a.nav-link'
    if (this.data.has('selector')) {
      this.selector = this.data.get('selector')
    }

    this.matcher = 'start'
    if (this.data.get('matcher')) {
      this.matcher = this.data.get('matcher')
    }

    this.attach()
  }

  attach() {
    let match_block;
    if ('equal' == this.matcher) {
      const match_string = window.location.pathname + window.location.search
      match_block = str => {
        return str == match_string
      }
    } else {
      const match_string = '^' + this.escape_as_regexp(window.location.pathname) + '\/?.*'
      match_block = str => {
        return match_string.match(this.escape_as_regexp(str))
      }
    }

    let nodes = this.element.querySelectorAll(this.selector)
    nodes = [...nodes]
    const matched_node = nodes.find(node => {
      return match_block(node.getAttribute('href'))
    })
    if (matched_node) {
      matched_node.classList.add('active')
    }
  }

  escape_as_regexp(str) {
    return str.replace(/[\\^$.*+?()[\]{}|]/g, '\\$&')
  }
}
