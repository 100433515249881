import React from 'react';
import PropTypes from 'prop-types';

import calcWithTax from '../../utilities/tax';
import InvalidErrorMessage from './InvalidErrorMessage';
import ProfitViewer from './Profit';
import FORM_ERROR_CLASS_NAME from './FormErrorClassName';

const disabledKey = 'price';
const blankErrorMessage = '販売価格を入力してください';

export default class PriceField extends React.Component {
  static MAX_PRICE_NUMBER = 8;

  constructor(props) {
    super(props);

    props.setDisabled(disabledKey, !props.price);

    this.state = {
      price: props.price,
      invalidPriceMessage: props.isInvalid && !props.price ? blankErrorMessage : null
    };

    this.onChangePrice = this.onChangePrice.bind(this);
  }

  onChangePrice(e) {
    let insertedPrice = e.target.value;

    let invalidMessage = null;
    if (!insertedPrice) {
      invalidMessage = blankErrorMessage;
    } else if (!insertedPrice.toString().match(/^[0-9]+$/)) {
      insertedPrice = null;
      invalidMessage = '価格は半角数字のみで入力してください';
    } else if (insertedPrice.toString().length > PriceField.MAX_PRICE_NUMBER) {
      insertedPrice = Number(insertedPrice.toString().slice(0, PriceField.MAX_PRICE_NUMBER));
      invalidMessage = `価格は${PriceField.MAX_PRICE_NUMBER}桁以下で入力してください`;
    } else if (insertedPrice < this.props.maxVariationPrice) {
      invalidMessage = 'アイテム原価以上の価格を入力してください';
    }

    this.props.setDisabled(disabledKey, Boolean(invalidMessage));
    this.setState({ price: insertedPrice, invalidPriceMessage: invalidMessage });
  }

  render() {
    return (
      <dl>
        <dt>
          <span className="__text">販売価格</span>
          <span className="__caption">PRICE</span>
        </dt>
        <dd>
          <h4 className="has_caution">アイテムの販売価格を入力してください。</h4>
          <p className="caution_message">
            ※アイテム原価
            {this.props.maxVariationPrice}
            円(税抜)以上の価格を設定してください。
          </p>
          <div className="price__field">
            <input
              type="number"
              name={this.props.fieldName}
              id="product_settings_price"
              className={this.state.invalidPriceMessage ? FORM_ERROR_CLASS_NAME : ''}
              value={this.state.price || ''}
              onChange={this.onChangePrice}
              pattern="^[0-9]+$"
            />
            <div className="unit">円</div>
            <div className="taxin__price">
              税込
              <span>{calcWithTax(this.state.price || 0).toLocaleString()}</span>
              円
            </div>
          </div>
          <div className="price_validation_error_wrap">
            {this.state.invalidPriceMessage ? (<InvalidErrorMessage invalidMessage={this.state.invalidPriceMessage} />) : ''}
          </div>
          <h5>利益</h5>
          <ul className="profits__list">
            {this.props.profits.map((profit, index) => (
              /* eslint-disable react/no-array-index-key */
              <ProfitViewer
                key={index}
                profit={calcWithTax(this.state.price || 0) - profit.price_with_tax}
                colorName={profit.color_name}
                sizeName={profit.size_name}
              />
              /* eslint-enable react/no-array-index-key */
            ))}
          </ul>
        </dd>
      </dl>
    );
  }
}

PriceField.defaultProps = {
  price: 0,
  isInvalid: false
};
PriceField.propTypes = {
  price: PropTypes.number,
  maxVariationPrice: PropTypes.number.isRequired,
  profits: PropTypes.arrayOf(PropTypes.object).isRequired,
  setDisabled: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool,
  fieldName: PropTypes.string.isRequired
};
