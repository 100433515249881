import { Controller } from "stimulus"
import * as lib from 'src/javascripts/concerns'


export default class extends Controller {
  static targets = [ "flash" ]


  add() {
    this.perform(event.detail[0])
  }

  addByFlash() {
    let dataset, data
    this.flashTargets.forEach((element, index) => {
      data = {
        state:   element.dataset.state,
        title:   element.dataset.title,
        message: element.dataset.message,
        delay:   element.dataset.delay
      }
      this.perform(data)
    })
  }

  perform(data) {
    lib.addToast(data)
  }
}
