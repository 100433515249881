import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import DataPickerCalender from './DataPickerCalender';
import formatDate from '../../utills/formatDate';

export default class ProceedDatePicker extends React.Component {
  static slickConfig() {
    return {
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 0,
      draggable: false
    };
  }

  static jumpTargetYear(date, direction, step = 1) {
    const year = date.getFullYear();
    const jumpedYear = direction === 'next' ? year + step : year - step;

    return new Date(jumpedYear, date.getMonth(), date.getDate());
  }

  static nextYear(date) {
    return ProceedDatePicker.jumpTargetYear(date, 'next');
  }

  static lastYear(date) {
    return ProceedDatePicker.jumpTargetYear(date, 'prev');
  }

  static handleSpChangeDate(event) {
    const { target } = event;
    if (!target.value || target.value === '') return false;

    const targetMonth = target.value.replace(/-/, '/');
    window.location = `/creators/proceeds?target_month=${targetMonth}`;

    return true;
  }

  static handleToggleDataPicker() {
    const pickerWrap = document.getElementsByClassName('sales_date_picker')[0];
    if (pickerWrap.classList.contains('is-show')) {
      pickerWrap.classList.remove('is-show');
    } else {
      pickerWrap.classList.add('is-show');
    }
  }

  constructor(props) {
    super(props);

    const selectedDateInstance = new Date(props.selectedDate);
    this.state = {
      selectedDate: selectedDateInstance,
      selectedYear: selectedDateInstance.getFullYear()
    };

    this.nextSlick = this.nextSlick.bind(this);
    this.prevSlick = this.prevSlick.bind(this);
  }

  nextSlick() {
    this.setState((prevState) => (
      { selectedDate: ProceedDatePicker.nextYear(prevState.selectedDate) }
    ));
  }

  prevSlick() {
    this.setState((prevState) => (
      { selectedDate: ProceedDatePicker.lastYear(prevState.selectedDate) }
    ));
  }

  datePickerDataList(_targetDate = null, dateList = [], step = 0) {
    if (step === 15) return dateList;

    let targetDate = _targetDate;
    if (!targetDate) {
      targetDate = new Date();
      dateList.push(targetDate);
    }

    const year = targetDate.getFullYear();
    const month = targetDate.getMonth();

    let prevMonth = month - 1;
    let prevYear = year;

    // 1月だった場合
    if (month === 0) {
      prevYear -= 1; // 前年に設定
      prevMonth = 11; // 12月を設定
    }

    const prevDate = new Date(prevYear, prevMonth);
    dateList.push(prevDate);

    return this.datePickerDataList(prevDate, dateList, step + 1);
  }


  render() {
    return (
      <>
        <div className="sp__datepicker__wrap">
          <select className="sp__datepicker" onChange={ProceedDatePicker.handleSpChangeDate} defaultValue={this.props.selectedDate}>
            {this.datePickerDataList().map((date) => (
              <option
                key={formatDate(date, 'yyyy-MM')}
                value={formatDate(date, 'yyyy-MM')}
              >
                {formatDate(date, 'yyyy年MM月')}
              </option>
            ))}
          </select>
        </div>
        <div className="sales_date_picker">
          <button type="button" className="__datepicker" onClick={ProceedDatePicker.handleToggleDataPicker}>{this.props.selectedDate}</button>
          <div className="sale__date__tooltip">
            <Slider className="sale__carousel" {...ProceedDatePicker.slickConfig()}>
              <DataPickerCalender
                year={this.state.selectedDate.getFullYear()}
                selectedMonth={this.state.selectedDate.getMonth() + 1}
                selectedYear={this.state.selectedYear}
              />
            </Slider>
            <div className="prevbutton" onClick={this.prevSlick} />
            <div className="nextbutton" onClick={this.nextSlick} />
          </div>
        </div>
      </>
    );
  }
}

ProceedDatePicker.propTypes = {
  selectedDate: PropTypes.string.isRequired
};
