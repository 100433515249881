import React from 'react';
import PropTypes from 'prop-types';

export default class SizeSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isChecked: this.props.isChecked
    };

    this.onChangeSize = this.onChangeSize.bind(this);
  }

  onChangeSize() {
    this.setState((prevState) => ({ isChecked: !prevState.isChecked }));

    this.props.toggleSelectedSizes(this.props.id, this.props.name, !this.state.isChecked);
  }

  render() {
    return (
      <li key={this.props.id}>
        <input id={`basic-settings-size-${this.props.id}`} checked={this.state.isChecked} className="__items" type="checkbox" value={this.props.id} name={`${this.props.scope}[size_ids][]`} onChange={this.onChangeSize} />
        <label htmlFor={`basic-settings-size-${this.props.id}`} className={this.state.isChecked ? 'selected' : ''}>{this.props.name}</label>
      </li>
    );
  }
}

SizeSelector.defaultProps = {
  isChecked: false,
  scope: 'basic_settings'
};

SizeSelector.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  toggleSelectedSizes: PropTypes.func.isRequired,
  isChecked: PropTypes.bool,
  scope: PropTypes.string
};
