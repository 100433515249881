import React from 'react';
import PropTypes from 'prop-types';

import RailsCsrfToke from '../shared/RailsCsrfToken';
import SelectedStandard from './shared/SelectedStandard';
import EnabledColorViewer from './shared/Forms/EnabledColorViewer';
import EnabledSizeViewer from './shared/Forms/EnabledSizeViewer';
import EnabledPositionViewer from './shared/Forms/EnabledPositionViewer';
import SizeDataModal from './shared/SizeDataModal';

export default class BasicSettingsForm extends React.Component {
  static handleDisabled(colors, sizes, positionName) {
    return !Object.keys(colors).length || !Object.keys(sizes).length || !positionName.length;
  }

  constructor(props) {
    super(props);

    this.state = {
      selectedColors: {},
      selectedSizes: {},
      selectedPosition: this.props.positions[0].name,
      selectedPositionCode: this.props.positions[0].code,
      disabled: true
    };

    this.toggleSelectedColors = this.toggleSelectedColors.bind(this);
    this.toggleSelectedSizes = this.toggleSelectedSizes.bind(this);
    this.setSelectedPosition = this.setSelectedPosition.bind(this);
  }

  setSelectedPosition(event) {
    const index = event.target.selectedIndex;
    const positionCode = event.target[index].value;
    const positionName = event.target[index].text;

    this.setState((prevState) => {
      const disabled = BasicSettingsForm.handleDisabled(
        prevState.selectedColors,
        prevState.selectedSizes,
        positionName
      );

      return {
        selectedPosition: positionName,
        selectedPositionCode: positionCode,
        disabled
      };
    });
  }

  toggleSelectedColors(id, name, hex, isChecked = true) {
    this.setState((prevState) => {
      const currentSelects = prevState.selectedColors;

      if (isChecked) {
        currentSelects[id] = { name, hex };
      } else {
        delete currentSelects[id];
      }

      const disabled = BasicSettingsForm.handleDisabled(
        currentSelects,
        prevState.selectedSizes,
        prevState.selectedPosition
      );

      return { selectedColors: currentSelects, disabled };
    });
  }

  toggleSelectedSizes(id, name, isChecked = true) {
    this.setState((prevState) => {
      const currentSelectedSizes = prevState.selectedSizes;

      if (isChecked) {
        currentSelectedSizes[id] = { name };
      } else {
        delete currentSelectedSizes[id];
      }

      const disabled = BasicSettingsForm.handleDisabled(
        prevState.selectedColors,
        currentSelectedSizes,
        prevState.selectedPosition
      );

      return {
        selectedSizes: currentSelectedSizes,
        disabled
      };
    });
  }

  formAction() {
    return `/creators/master_products/${this.props.masterProductId}/basic_settings`;
  }

  render() {
    return (
      <form action={this.formAction()} method="post" acceptCharset="UTF-8">
        <RailsCsrfToke />

        <div className="box__wt item__configurator">
          <figure className="item__surface">
            <img src={this.props.thumbnailUrl} alt="バリエーション画像" />
          </figure>
          <SizeDataModal sizeDataImageUrl={this.props.sizeDataImageUrl} />

          <div className="item__configurator__body">
            <EnabledColorViewer
              colors={this.props.colors}
              toggleSelectedColors={this.toggleSelectedColors}
              selectedColorIds={this.state.selectedColors.keys}
            />
            <EnabledSizeViewer
              sizes={this.props.sizes}
              toggleSelectedSizes={this.toggleSelectedSizes}
              selectedSizeIds={this.state.selectedSizes.keys}
            />
            <EnabledPositionViewer
              positions={this.props.positions}
              setSelectedPosition={this.setSelectedPosition}
              selectedPositionCode={this.state.selectedPositionCode}
            />

            <hr />
            <div className="seleted__parameters">
              <h3>選択された項目</h3>
              <div className="title__caption">DESIGN ITEM</div>
              <dl>
                <dt>選択したカラー</dt>
                <dd>
                  <ul>
                    {Object.keys(this.state.selectedColors).map((colorId) => (
                      <SelectedStandard
                        key={colorId}
                        id={colorId}
                        name={this.state.selectedColors[colorId].name}
                        hex={this.state.selectedColors[colorId].hex}
                      />
                    ))}
                  </ul>
                </dd>
              </dl>
              <dl>
                <dt>選択したサイズ</dt>
                <dd>
                  <ul>
                    {Object.keys(this.state.selectedSizes).map((sizeId) => (
                      <SelectedStandard
                        key={sizeId}
                        id={sizeId}
                        name={this.state.selectedSizes[sizeId].name}
                      />
                    ))}
                  </ul>
                </dd>
              </dl>
              <dl>
                <dt>選択した配置</dt>
                <dd>
                  <ul id="js-selected-arrangement-list">
                    <SelectedStandard
                      id={1}
                      name={this.state.selectedPosition}
                    />
                  </ul>
                </dd>
              </dl>
            </div>
            <div className="form__action">
              <ul>
                <li>
                  <a className="btn__default" href="/creators/master_products">アイテム選択からやり直す</a>
                </li>
                <li>
                  <input type="submit" name="commit" value="デザイン選択にすすむ" className="btn__edit __normal" id="basic-setting-submit" disabled={this.state.disabled ? 'disabled' : ''} />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

BasicSettingsForm.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.object).isRequired,
  sizes: PropTypes.arrayOf(PropTypes.object).isRequired,
  positions: PropTypes.arrayOf(PropTypes.object).isRequired,
  masterProductId: PropTypes.number.isRequired,
  thumbnailUrl: PropTypes.string.isRequired,
  sizeDataImageUrl: PropTypes.string.isRequired
};
