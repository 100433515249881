import React from 'react';
import PropTypes from 'prop-types';

import InvalidErrorMessage from './InvalidErrorMessage';
import FORM_ERROR_CLASS_NAME from './FormErrorClassName';

const disabledKey = 'description';
const blankErrorMessage = 'アイテム説明を入力してください';

export default class DescriptionField extends React.Component {
  constructor(props) {
    super(props);

    props.setDisabled(disabledKey, !props.description);

    this.state = {
      description: props.description || '',
      invalidDescriptionMessage: props.isInvalid && !props.description ? blankErrorMessage : null
    };

    this.onChangeDescription = this.onChangeDescription.bind(this);
  }

  onChangeDescription(e) {
    const insertedDescription = e.target.value;

    let invalidMessage = null;
    if (!insertedDescription) invalidMessage = blankErrorMessage;

    this.props.setDisabled(disabledKey, Boolean(invalidMessage));
    this.setState({ description: insertedDescription, invalidDescriptionMessage: invalidMessage });
  }

  render() {
    return (
      <dl>
        <dt>
          <span className="__text">アイテム説明</span>
          <span className="__caption">ITEM DESCRIPTION</span>
        </dt>
        <dd>
          <h4>アイテム説明を入力してください。</h4>
          <textarea
            name={this.props.fieldName}
            id="product_settings_description"
            value={this.state.description}
            onChange={this.onChangeDescription}
            className={this.state.invalidDescriptionMessage ? FORM_ERROR_CLASS_NAME : ''}
          />
          <div className="description_validation_error_wrap">
            {this.state.invalidDescriptionMessage ? (<InvalidErrorMessage invalidMessage={this.state.invalidDescriptionMessage} />) : ''}
          </div>
        </dd>
      </dl>
    );
  }
}

DescriptionField.defaultProps = {
  description: '',
  isInvalid: false
};
DescriptionField.propTypes = {
  description: PropTypes.string,
  setDisabled: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool,
  fieldName: PropTypes.string.isRequired
};
