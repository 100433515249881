import React from 'react';
import PropTypes from 'prop-types';

import FORM_ERROR_CLASS_NAME from './FormErrorClassName';

const InvalidErrorMessage = (props) => {
  if (!props.invalidMessage) return '';

  return <span className={FORM_ERROR_CLASS_NAME}>{props.invalidMessage}</span>;
};

InvalidErrorMessage.defaultProps = {
  invalidMessage: null
};
InvalidErrorMessage.propTypes = {
  invalidMessage: PropTypes.string
};

export default InvalidErrorMessage;
