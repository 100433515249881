import React from 'react';
import PropTypes from 'prop-types';

const EnabledPositionViewer = (props) => (
  <dl>
    <dt>
      <span className="__text">配置を選択</span>
      <span className="__caption">SIZE</span>
    </dt>
    <dd>
      <h4>デザインを配置したい場所を選択してください。</h4>
      <select name="basic_settings[position_code]" id="basic_settings_position_id" onChange={props.setSelectedPosition} value={props.selectedPositionCode}>
        {props.positions.map((position) => (
          <option key={`${position.code}_${position.name}`} value={position.code}>
            {position.name}
          </option>
        ))}
      </select>
    </dd>
  </dl>
);

EnabledPositionViewer.defaultProps = {
  setSelectedPosition: () => { /* 何もしない */ },
  selectedPositionCode: ''
};

EnabledPositionViewer.propTypes = {
  positions: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSelectedPosition: PropTypes.func,
  selectedPositionCode: PropTypes.string
};

export default EnabledPositionViewer;
