import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = []
  static values = {
    path: String
  }

  connect() {
    this.reindex()
  }

  reindex() {
    this.element.querySelectorAll('tr[data-id').forEach(tr => {
      tr.querySelector('td[data-type="large"]').innerHTML = '<i class="fas fa-sync fa-spin"></i>'
      tr.querySelector('td[data-type="medium"]').innerText = ''
      tr.querySelector('td[data-type="small"]').innerText = ''
    })

    fetch(this.pathValue, {
    }).then(response => response.json()
    ).then(categories => {
      this.element.querySelectorAll('tr[data-id').forEach(tr => {
        let herokura = categories[tr.dataset.id]

        tr.querySelector('td[data-type="large"]').innerText = herokura.large.name
        tr.querySelector('td[data-type="medium"]').innerText = herokura.medium.name
        tr.querySelector('td[data-type="small"]').innerText = herokura.small.name
      })
    })
  }
}
