import { Controller } from "stimulus"


export default class extends Controller {
  perform() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
}
